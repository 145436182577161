<template>
  <v-row align="center" justify="center" no-gutters class="mt-12">
    <v-col v-for="(titulo, index) of titulos" :key="index" cols="12">
      <h1
        v-if="$vuetify.breakpoint.smAndDown"
        class="text-center title--text font-weight-bold text-h4"
      >
        ¡Te aseguramos el mejor valor!
      </h1>
      <h1 v-else class="text-center title--text font-weight-regular text-h3">
        {{ titulo }}
      </h1>
    </v-col>
    <v-col cols="12" class="mb-8">
      <h3
        v-for="(title, index) of subtitles"
        :key="index"
        class="text-center title--text font-weight-light my-3"
      >
        {{ title }}
      </h3>
    </v-col>
    <v-col cols="11" md="8" xl="6" lg="6">
      <formulariopetersen-compra consignment />
    </v-col>
  </v-row>
</template>

<script>
import FormulariopetersenCompra from '@/components/core/formulariopetersenCompra';

export default {
  name: 'consignacion',
  components: {FormulariopetersenCompra},
  data: () => ({
    titulos: ['¡Te aseguramos el mejor valor!'],
    subtitles: [
      '- No compramos  vehículos con mas de 90.000kms',
      '- No compramos  vehículos chocados o reparados',
      '- Al consignarlo, obtienes una oferta mucho mas alta',
      '- Si tiene prenda, no podemos consignarlo, pero si comprarlo pagando inmediatamente',
    ],
  }),
};
</script>

<style scoped></style>
